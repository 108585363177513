import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { OurOtherOffering } from "../../assests/data";

const ServiceDetailCard = ({ title }) => {
    const theme = useTheme();
    const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  const data = OurOtherOffering[title];
  return (
    <>
      <div className="space-y-2 mb-4">
        <Typography variant={isMatched ? "h4" : "h3"}>{title}</Typography>
        <div className="bg-[#EC8A00] h-0.5 w-32"></div>
      </div>
      <Typography variant="p" className="text-lg font-normal text-gray-500">
        {data.description}
      </Typography>
      <div className="space-y-1 p-4">
        {data.infoPoints.map((item) => {
          return (
            <div className="flex space-x-2 items-center">
              <div className="w-2 h-2 bg-blue-700"></div>
              <Typography variant="subtitle1">{item}</Typography>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end self-end">
        <Button variant="text"> Learn More</Button>
      </div>
    </>
  );
};

export default ServiceDetailCard;
