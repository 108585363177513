import React from "react";
import { Routes, Route, Router, BrowserRouter } from "react-router-dom";
import About from "./About/About";
import Home from "./Home/Home";
import ServicePage3 from "./Services/ServicePage3";
import ServicePage4 from "./Services/ServicePage4";
import ServicesMainPage from "./Services/ServicesMainPage";

const RouterPage = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route index element={<Home />} /> */}
          <Route path="/about" element={<About />} />
          <Route
            path="/services/ecommerce-website"
            element={<ServicesMainPage page={1} title="ecommerce-website" />}
          />
          <Route
            path="/services/business-website"
            element={<ServicesMainPage page={2} title="business-website" />}
          />
          <Route
            path="/services/news-website"
            element={<ServicesMainPage page={1} title="news-website" />}
          />

          {/* Industries */}
          <Route
            path="/industries/healthcare"
            element={<ServicePage3  title="Healthcare" />}
          />
          <Route
            path="/industries/banking"
            element={<ServicePage3  title="Banking" />}
          />
          <Route
            path="/industries/manufacturing"
            element={<ServicePage3  title="manufacturing" />}
          />
          <Route
            path="/industries/information-technologies"
            element={<ServicePage3  title="Information Technologies" />}
          />

          {/* Solutions */}
          <Route
            path="/solution/enterprise-applications"
            element={<ServicePage4  title="Enterprise applications" />}
          />
          <Route
            path="/solution/erp"
            element={<ServicePage4  title="ERP" />}
          />
          <Route
            path="/solution/crm"
            element={<ServicePage4  title="CRM" />}
          />
          <Route
            path="/solution/hr-software"
            element={<ServicePage4  title="HR Software" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default RouterPage;
