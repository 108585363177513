import RouterPage from "./RouterPage";

function App() {
  return (
    <div className="">
      <RouterPage />
    </div>
  );
}

export default App;
