import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {BsArrowRight} from "react-icons/bs"
import image1 from "../../assests/image1.png";
import image2 from "../../assests/image2.jpg";
import google from "../../assests/google.png";
import ibm from "../../assests/ibm.jpg";
import meta from "../../assests/meta.png";
import microsoft from "../../assests/microsoft.jpg";
import linkedin from "../../assests/linkedin.png";
import automotive from "../../assests/automotive.jpeg";
import { Typography } from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";

const OurIndustries = [
  {
    heading: "Finance",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "Retail",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "Telecom",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "Cloud",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "Hitech",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "HealthCare",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "Education",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
  {
    heading: "Government",
    description:
      "Our Solution power all aspects digital finance analytics and more asuring against fraud and any other complainces.",
  },
];
const Industries = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div id="industries" className="bg-white pb-10">
      <div className="opacity-90">
        <img
          src={automotive}
          style={{
            height:isMatched? 500: "100%",
            width:isMatched? 395:  "100%",
          }}
        />
      </div>

      <div style={{ marginTop: isMatched ? -500: -684 }} className="">
        <CarouselProvider
          naturalSlideWidth={isMatched ? 39: 50}
          naturalSlideHeight={isMatched ? 100: 75}
          totalSlides={8}
          infinite={true}
          visibleSlides={isMatched ? 2: 3}
        >
          <Slider>
            {OurIndustries.map((item, index) => (
              <Slide index={index}>
                {/* <Image src={image1} /> */}
                <div className="border border-b-0 px-16  h-full flex justify-center items-center">
                  <div className="space-y-8">
                    <Typography
                      level="p"
                      className="lg:text-4xl text-2xl text-white font-bold "
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      level="p"
                      className="text-lg text-white font-normal "
                    >
                      {item.description}
                    </Typography>
                    <div className="flex items-center space-x-4">
                    <Typography
                      level="p"
                      className="text-3xl text-[#FF04D5] font-semibold "
                    >
                      Learn More
                      
                    </Typography><BsArrowRight color="#FF04D5" size={32} /></div>
                  </div>
                </div>
              </Slide>
            ))}
          </Slider>
          {/* <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext> */}
        </CarouselProvider>
      </div>
    </div>
  );
};

export default Industries;
