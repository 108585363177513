import { Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { FiArrowUpRight } from "react-icons/fi";
import ServicePage3 from "../../Services/ServicePage3";
import { Link } from "react-router-dom";
// import

const OurSolutions = [
  { link: "healthcare", title: "Healthcare" },
  { link: "banking", title: "Banking" },
  { link: "manufacturing", title: "Manufacturing" },
  { link: "information-technologies", title: "Information Technologies" },
  { link: "oil-and-gas", title: "Oil & Gas" },
  { link: "retail", title: "Retail" },
  { link: "telecommunication", title: "Telecommunication" },
  { link: "logistics-and-transportation", title: "Logistics & Transportation" },
  { link: "insurance", title: "Insurance" },
  { link: "professional-services", title: "Professional Services" },
  { link: "marketing-and-advertising", title: "Marketing & Advertising" },
  { link: "public-services", title: "Public Services" },
];

const AllIndustriesList = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    height: isMatched ? 100 : 130,
    width: isMatched ? 360 : 280,
    cursor: "pointer",
    // borderRadius: 0,
    // borderWidth: 0.5,
    // borderColor: "black",
    color: theme.palette.text.secondary,
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    // background: "rgb(2,0,36)",
    // background:
    //   "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(0,212,255,1) 100%)",
    boxShadow: "0 3px 5px 2px rgba(150, 150, 150, .5)",
  }));
  return (
    <div className="lg:p-16 p-4 bg-white space-y-10">
      <div className="space-y-2">
        <Typography
          variant="p"
          className="lg:text-4xl text-2xl text-black font-bold"
        >
          Industry Expertise
        </Typography>
        <div className="bg-[#EC8A00] h-0.5 lg:w-32 w-24 mb-8"></div>
        <div>
          <Typography
            variant="p"
            className="text-xl text-black lg:font-normal font-thin"
          >
            We’ve excelled our experience in a wide range of industries to bring
            valuable insights and provide our customers with the truly
            beneficial solutions.
          </Typography>
        </div>
      </div>
      <div>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          {OurSolutions.map((item) => (
            <Grid item xs={isMatched ? 12 : 3}>
              <Item>
                <Link to={`/industries/${item.link}`}>
                  <div className="flex self-center w-full justify-end items-end">
                    <FiArrowUpRight color="blue" size={20} />
                  </div>
                  <div className="lg:mt-6 mt-2">
                    <Typography
                      variant="p"
                      className="text-lg text-black font-semibold text-black"
                    >
                      {item.title}
                    </Typography>
                  </div>
                </Link>
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default AllIndustriesList;
