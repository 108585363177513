import OurBlog from "./AboutUs/OurBlog";
import OurOffer from "./AboutUs/OurOffer";
import WhyUs from "./AboutUs/WhyUs";
import Footer from "./Footer";
import BannerVideo from "./Header/BannerVideo";
import Header from "./Header/Header";
import HeaderCompanyNameSlider from "./Header/HeaderCompanyNameSlider";
import ItDetails from "./ITSolutions/ItDetails";
import ItManagedSolution from "./ITSolutions/ItManagedSolution";
import ItWarning from "./ITSolutions/ItWarning";
import AllIndustriesList from "./OurIndustries/AllIndustriesList";
import Industries from "./OurIndustries/Industries";
import Services from "./OurServices/Services";
import Solutions from "./OurSolutions/Solutions";
import Technology from "./OurTechnology/Technology";

function Home() {
  return (
    <div className="">
      <Header />
      <BannerVideo />
      <HeaderCompanyNameSlider />
      <ItWarning />
      <ItManagedSolution />
      <ItDetails />
      <Services />
      <Solutions />
      <Technology />
      <Industries  />
      <AllIndustriesList />
      <WhyUs />
      <OurOffer />
      {/* <OurBlog /> */}
      <Footer />
    </div>
  );
}

export default Home;
