import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import ServiceDetailCard from "./ServiceDetailCard";

const Services = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedOffering, setSelectedOffering] = useState(
    "Software Development"
  );
  return (
    <div
      id="services"
      className="md:p-16 p-4 bg-[#FAFAFA] lg:space-y-20 space-y-4"
    >
      <div className="space-y-2">
        <Typography
          variant="p"
          className={isMatched ? "text-xl font-bold" : "text-4xl font-bold"}
        >
          Explore Our Other Offering
        </Typography>
        <div className="bg-[#EC8A00] h-0.5 w-16 md:w-28"></div>
      </div>
      <div
        className={
          isMatched ? "bg-white flex flex-col" : "bg-white w-full flex p-16"
        }
        style={{
          boxShadow: "0 2px 5px 5px rgba(150,150,150,0.3)",
        }}
      >
        <div
          className={
            isMatched
              ? "w-full bg-[#0B3155]"
              : "w-1/3 absolute bg-[#0B3155] -mt-24 -ml-24"
          }
        >
          <ul className="">
           
            <li
              className={
                selectedOffering === "Software Development"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Software Development")}
            >
              Software Development
            </li>
            <li
              className={
                selectedOffering === "Testing & QA"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl  lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Testing & QA")}
            >
              Testing & QA
            </li>
            <li
              className={
                selectedOffering === "Application Services"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl  lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Application Services")}
            >
              Application Services
            </li>
            <li
              className={
                selectedOffering === "UX/UI Design"
                  ? "text-white text-xl  lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("UX/UI Design")}
            >
              UX/UI Design
            </li>
            <li
              className={
                selectedOffering === "IT Consulting"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("IT Consulting")}
            >
              IT Consulting
            </li>
            <li
              className={
                selectedOffering === "Data Analytics"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl  lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Data Analytics")}
            >
              Data Analytics
            </li>
            <li
              className={
                selectedOffering === "Help Desk Services"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl  lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Help Desk Services")}
            >
              Help Desk Services
            </li>
            <li
              className={
                selectedOffering === "Infrastructure Services"
                  ? "text-white text-xl  lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl  lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Infrastructure Services")}
            >
              Infrastructure Services
            </li>
            <li
              className={
                selectedOffering === "Cybersecurity Services"
                  ? "text-white text-xl lg:p-5 p-3 bg-blue-600 font-medium cursor-pointer"
                  : "text-white text-xl lg:p-5 p-3 font-medium cursor-pointer"
              }
              onClick={() => setSelectedOffering("Cybersecurity Services")}
            >
              Cybersecurity Services
            </li>
          </ul>
        </div>
        {isMatched ? <></> : <div className="w-1/3"></div>}

        <div className="lg:w-2/3 w-full lg:ml-32 p-2" style={{
          height: isMatched ? "100%" : 500
        }}>
          <ServiceDetailCard title={selectedOffering} />
        </div>
      </div>
    </div>
  );
};

export default Services;
