import React from "react";

const TechnologyIconCard = ({IconName}) => {
  return (
    <>
      <div
        className="w-20 h-16 bg-white p-2 px-4 flex items-center"
        style={{
          boxShadow: "0 0 5px 2px rgba(150,150,150,0.5)",
        }}
      >
        <IconName />
      </div>
    </>
  );
};

export default TechnologyIconCard;
