import { Typography, useMediaQuery, useTheme, Box } from "@mui/material";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import React from "react";
import { BsCheckLg } from "react-icons/bs";

const OurOffer = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div id="pricing">
      {isMatched ? (
        <>
          <div className="mb-10">
            <CarouselProvider
              naturalSlideHeight={100}
              naturalSlideWidth={63}
              totalSlides={3}
              visibleSlides={1}
            >
              <Slider>
                <Slide>
                  <Box>
                    <div
                      className="w-full h-full rounded-2xl border  p-12 space-y-12 mr-1 text-white"
                      style={{
                        background: "rgb(151,16,199)",
                        background:
                          "linear-gradient(180deg, rgba(151,16,199,1) 17%, rgba(19,27,179,1) 65%, rgba(228,51,51,1) 100%)",
                      }}
                    >
                      <div className="flex space-x-1">
                        <Typography
                          variant="p"
                          className="text-lg font-semibold"
                        >
                          ₹
                        </Typography>
                        <Typography
                          variant="p"
                          className="text-5xl font-semibold"
                        >
                          999
                        </Typography>
                        <Typography
                          variant="p"
                          className="text-2xl text-[#7582EB] font-semibold self-end line-through"
                        >
                          1100
                        </Typography>
                      </div>
                      <div className="">
                        <div>
                          <Typography
                            variant="p"
                            className="text-2xl font-bold"
                          >
                            Personal
                          </Typography>
                        </div>
                        <Typography
                          variant="p"
                          className="text-lg font-normal"
                        >
                          Best Choice for Individual
                        </Typography>
                      </div>
                      <div className="w-full h-12 rounded border flex justify-center items-center">
                        <Typography
                          variant="p"
                          className="text-lg font-semibold"
                        >
                          Buy Now
                        </Typography>
                      </div>
                      <div className="space-y-4">
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            LifeTime
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Usage
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Unlimited
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Support & Updates
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Free Custom
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Domain
                          </Typography>
                        </div>
                      </div>
                      <div className="w-full h-16"></div>
                    </div>
                  </Box>
                </Slide>
                <Slide>
                  <Box>
                    <div className="w-full h-full rounded-2xl border  p-12 space-y-12 mr-1 text-white"
                      style={{
                        background: "rgb(151,16,199)",
                        background:
                          "linear-gradient(180deg, rgba(151,16,199,1) 17%, rgba(19,27,179,1) 65%, rgba(228,51,51,1) 100%)",
                      }}>
                      <div className="flex space-x-1">
                        <Typography
                          variant="p"
                          className="text-lg font-semibold"
                        >
                          ₹
                        </Typography>
                        <Typography
                          variant="p"
                          className="text-5xl font-semibold"
                        >
                          9999
                        </Typography>
                        <Typography
                          variant="p"
                          className="text-2xl text-[#7582EB] font-semibold self-end line-through"
                        >
                          11000
                        </Typography>
                      </div>
                      <div className="">
                        <div>
                          <Typography
                            variant="p"
                            className="text-2xl font-bold"
                          >
                            Business
                          </Typography>
                        </div>
                        <Typography
                          variant="p"
                          className="text-lg font-normal"
                        >
                          Best Choice for Priority Support
                        </Typography>
                      </div>
                      <div className="w-full h-12 rounded border flex justify-center items-center">
                        <Typography
                          variant="p"
                          className="text-lg font-semibold"
                        >
                          Buy Now
                        </Typography>
                      </div>
                      <div className="space-y-4">
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            LifeTime
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Usage
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Unlimited
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Support & Updates
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Free Custom
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Domain
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Mobile-Optimizes
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Websites
                          </Typography>
                        </div>
                      </div>
                      <div className="w-full h-6"></div>
                    </div>
                  </Box>
                </Slide>
                <Slide>
                  <Box>
                    <div className="w-full h-full rounded-2xl border  p-12 space-y-12 mr-1 text-white"
                      style={{
                        background: "rgb(151,16,199)",
                        background:
                          "linear-gradient(180deg, rgba(151,16,199,1) 17%, rgba(19,27,179,1) 65%, rgba(228,51,51,1) 100%)",
                      }}>
                      <div className="flex space-x-1">
                        <Typography
                          variant="p"
                          className="text-lg font-semibold"
                        >
                          ₹
                        </Typography>
                        <Typography
                          variant="p"
                          className="text-5xl font-semibold"
                        >
                          99999
                        </Typography>
                        <Typography
                          variant="p"
                          className="text-2xl text-[#7582EB] font-semibold self-end line-through"
                        >
                          110000
                        </Typography>
                      </div>
                      <div className="">
                        <div>
                          <Typography
                            variant="p"
                            className="text-2xl font-bold"
                          >
                            Enterprise
                          </Typography>
                        </div>
                        <Typography
                          variant="p"
                          className="text-lg font-normal"
                        >
                          Explore Support of Our Primium Solution for Enterprise
                        </Typography>
                      </div>
                      <div className="w-full h-12 rounded border flex justify-center items-center">
                        <Typography
                          variant="p"
                          className="text-lg font-semibold"
                        >
                          Buy Now
                        </Typography>
                      </div>
                      <div className="space-y-4">
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            LifeTime
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Usage
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Unlimited
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Support & Updates
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Free Custom
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Domain
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            Mobile-Optimizes
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Websites
                          </Typography>
                        </div>
                        <div className="flex items-center space-x-1">
                          <BsCheckLg color="#C5BDC8" size={16} />
                          <Typography variant="p" className="text-lg font-bold">
                            24/7
                          </Typography>
                          <Typography variant="p" className="text-lg">
                            Customer Support
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        </>
      ) : (
        <div className="flex w-full p-10">
          <div
            className="w-1/3 rounded-3xl border p-12 space-y-12 mr-1"
            style={{
              background: "rgb(241,0,69)",
              background:
                "linear-gradient(90deg, rgba(252, 92, 125,1) 0%, rgba(106, 130, 251.1) 99%)",
            }}
          >
            <div className="flex space-x-1">
              <Typography
                variant="p"
                className="text-lg text-white  font-semibold"
              >
                ₹
              </Typography>
              <Typography
                variant="p"
                className="text-5xl text-white font-semibold"
              >
                999
              </Typography>
              <Typography
                variant="p"
                className="text-2xl text-white  font-semibold self-end line-through"
              >
                1100
              </Typography>
            </div>
            <div className="">
              <div>
                <Typography
                  variant="p"
                  className="text-2xl text-white  font-bold"
                >
                  Personal
                </Typography>
              </div>
              <Typography
                variant="p"
                className="text-lg  text-white  font-normal"
              >
                Best Choice for Individual
              </Typography>
            </div>
            <div
              className="w-full h-12 rounded  flex justify-center items-center"
              style={{
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
              }}
            >
              <Typography
                variant="p"
                className="text-lg text-white font-semibold"
              >
                Buy Now
              </Typography>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white font-bold"
                >
                  LifeTime
                </Typography>
                <Typography variant="p" className="text-lg  text-white ">
                  Usage
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Unlimited
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Support & Updates
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg  text-white font-bold"
                >
                  Free Custom
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Domain
                </Typography>
              </div>
            </div>
          </div>
          <div
            className="w-1/3 rounded-3xl border p-12 space-y-12 mr-1"
            style={{
              background: "rgb(241,0,69)",
              background:
                "linear-gradient(90deg, rgba(252, 92, 125,1) 0%, rgba(106, 130, 251.1) 99%)",
            }}
          >
            <div className="flex space-x-1">
              <Typography
                variant="p"
                className="text-lg text-white font-semibold"
              >
                ₹
              </Typography>
              <Typography
                variant="p"
                className="text-5xl text-white  font-semibold"
              >
                9999
              </Typography>
              <Typography
                variant="p"
                className="text-2xl text-[#fff] font-semibold self-end line-through"
              >
                11000
              </Typography>
            </div>
            <div className="">
              <div>
                <Typography
                  variant="p"
                  className="text-2xl text-white  font-bold"
                >
                  Business
                </Typography>
              </div>
              <Typography
                variant="p"
                className="text-lg text-white font-normal"
              >
                Best Choice for Priority Support
              </Typography>
            </div>
            <div
              className="w-full h-12 rounded  flex justify-center items-center"
              style={{
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
              }}
            >
              <Typography
                variant="p"
                className="text-lg text-white  font-semibold"
              >
                Buy Now
              </Typography>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  LifeTime
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Usage
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Unlimited
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Support & Updates
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Free Custom
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Domain
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Mobile-Optimizes
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Websites
                </Typography>
              </div>
            </div>
          </div>
          <div
            className="w-1/3 rounded-3xl border p-12 space-y-12 mr-1"
            style={{
              background: "rgb(241,0,69)",
              background:
                "linear-gradient(90deg, rgba(252, 92, 125,1) 0%, rgba(106, 130, 251.1) 99%)",
            }}
          >
            <div className="flex space-x-1">
              <Typography
                variant="p"
                className="text-lg text-white  font-semibold"
              >
                ₹
              </Typography>
              <Typography
                variant="p"
                className="text-5xl text-white  font-semibold"
              >
                99999
              </Typography>
              <Typography
                variant="p"
                className="text-2xl text-[#fff] font-semibold self-end line-through"
              >
                110000
              </Typography>
            </div>
            <div className="">
              <div>
                <Typography
                  variant="p"
                  className="text-2xl text-white  font-bold"
                >
                  Enterprise
                </Typography>
              </div>
              <Typography
                variant="p"
                className="text-sm  text-white  font-normal"
              >
                Explore Support of Our Primium Solution for Enterprise
              </Typography>
            </div>
            <div
              className="w-full h-12 rounded  flex justify-center items-center"
              style={{
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
                boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
              }}
            >
              <Typography
                variant="p"
                className="text-lg text-white  font-semibold"
              >
                Buy Now
              </Typography>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  LifeTime
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Usage
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Unlimited
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Support & Updates
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Free Custom
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Domain
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  Mobile-Optimizes
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Websites
                </Typography>
              </div>
              <div className="flex items-center space-x-1">
                <BsCheckLg color="#fff" size={16} />
                <Typography
                  variant="p"
                  className="text-lg text-white  font-bold"
                >
                  24/7
                </Typography>
                <Typography variant="p" className="text-lg text-white ">
                  Customer Support
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurOffer;
