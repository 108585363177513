import { Typography } from '@mui/material'
import React from 'react'
import Heading from '../../components/Heading'

const Industrycard = ({data}) => {
  return (
    <div className="w-full flex flex-col lg:flex-row border rounded-xl ">
        <div className='lg:w-1/4'>
            <img src={data.cardImage[data.cardImageName]} style={{
                height: 250,
                borderRadius: 20,
                
            }} />
        </div>
        <div className='lg:w-3/4 lg:p-5 lg:pl-8 p-2 space-y-4'>
            <Heading title={data.cardHeading} titleType="h5" lineSize={24} />
            <Typography variant='body1'>{data.cardDescription}</Typography>
        </div>

    </div>
  )
}

export default Industrycard