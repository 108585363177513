import { Typography } from "@mui/material";
import React from "react";
import TechnologyIconCard from "./TechnologyIconCard";

const TechnologyCard = ({title, iconList}) => {
  return (
    <>
      <div className="space-y-4">
        <div className="space-y-2">
          <Typography variant="p" className="text-4xl font-bold">
            {title}
          </Typography>
          <div className="bg-[#EC8A00] h-0.5 w-20"></div>
        </div>
        
        <div className="flex space-x-2 ">
          {iconList.map((item) => {
            return <TechnologyIconCard IconName={item} />;
          })}
        </div>
       
      </div>
    </>
  );
};

export default TechnologyCard;
