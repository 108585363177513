import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import image1 from "../../assests/image1.png";
import image2 from "../../assests/image2.jpg";
import google from "../../assests/google.png";
import ibm from "../../assests/ibm.jpg";
import meta from "../../assests/meta.png";
import microsoft from "../../assests/microsoft.jpg";
import linkedin from "../../assests/linkedin.png";
import walmart from "../../assests/walmart.png";
import { useMediaQuery, useTheme } from "@mui/material";

const HeaderCompanyNameSlider = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div id="associates" className="py-10">
      <CarouselProvider
        naturalSlideWidth={80}
        naturalSlideHeight={80}
        totalSlides={12}
        visibleSlides={isMatched ? 4 : 10}
      >
        <Slider>
          <Slide index={0}>
            <Image src={image1} />
          </Slide>
          <Slide index={1}>
            <Image src={microsoft} />
          </Slide>
          <Slide index={2}>
            <Image src={google} />
          </Slide>
          <Slide index={3}>
            <Image src={ibm} />
          </Slide>
          <Slide index={4}>
            <Image src={linkedin} />
          </Slide>
          <Slide index={5}>
            <Image src={walmart} />
          </Slide>
          <Slide index={6}>
            <Image src={meta} />
          </Slide>
          <Slide index={7}>
            <Image src={google} />
          </Slide>
          <Slide index={8}>
            <Image src={ibm} />
          </Slide>
          <Slide index={9}>
            <Image src={linkedin} />
          </Slide>
          <Slide index={10}>
            <Image src={walmart} />
          </Slide>
          <Slide index={11}>
            <Image src={meta} />
          </Slide>
        </Slider>
        {/* <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext> */}
      </CarouselProvider>
    </div>
  );
};

export default HeaderCompanyNameSlider;
