import { Typography } from "@mui/material";
import React from "react";

const Heading = ({ title, titleType, lineSize }) => {
  return (
    <div>
      <Typography variant={titleType}>{title}</Typography>
      <div className={`w-${lineSize} h-0.5 bg-[#EC8A00]`}></div>
    </div>
  );
};

export default Heading;
