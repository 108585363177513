import { Typography } from "@mui/material";
import React from "react";

const ServiceListCard = ({ title, list }) => {
  return (
    <div className="lg:w-1/3 border">
      <div className="w-full h-2 bg-blue-700"></div>
      <div className="p-4 space-y-4">
        <Typography variant="h6" color="primary">
          {title}
        </Typography>
        <div className="space-y-3">
          {list.map((item) => {
            return (
              <div className="flex items-center space-x-2">
                <div className="w-2 h-2 bg-blue-700"></div>
                <Typography variant="">{item}</Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceListCard;
