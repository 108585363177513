import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Header from "../Home/Header/Header";
import services from "../assests/automotive.jpg";
import ServiceCard from "./ServiceCard";
import it7 from "../assests/it7.png";
import ServicePage1 from "./ServicePage1";
import ServicePage2 from "./ServicePage2";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Footer from "../Home/Footer";

const ServicesMainPage = ({ page, title }) => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  const [expand, setExpand] = React.useState(false);
  return (
    <div>
      <Header />
      <div className="w-full flex flex-col lg:flex-row">
        <div
          className="lg:w-1/4 lg:p-4"
          style={{
            boxShadow: "5px 0 20px 0px rgba(180, 180, 180, .5)",
          }}
        >
          {isMatched ? (
            <div>
              <div
                className="bg-[#0B3155] p-2 px-5 flex justify-between items-center"
                onClick={() => setExpand(!expand)}
              >
                <Typography variant="subtitle1" className="" color="white">
                  {title}
                </Typography>
                <div className="text-white">
                <KeyboardArrowDownIcon />
                </div>
              </div>
              {expand && (
                <div>
                  <div className="lg:pt-8 pl-4 space-y-4">
                    <Typography variant="body1">
                      Software Product Development
                    </Typography>
                    <div className="pl-4 space-y-4">
                      <Typography>MVP Development</Typography>
                      <Typography>Startup Consulting</Typography>
                      <Typography>Software Development for Startups</Typography>
                      <Typography>How to Build a Software Startup</Typography>
                      <Typography>SaaS Consulting</Typography>
                      <Typography>SaaS Enhancement</Typography>
                      <Typography>How to Build a SaaS Startup</Typography>
                      <Typography>SaaS Development</Typography>
                      <Typography>VR Development</Typography>
                    </div>
                  </div>
                  <div className="pt-8 pl-4 space-y-4">
                    <Typography variant="body1">Technologies</Typography>
                    <div className="pl-4 space-y-4">
                      <Typography>Java</Typography>
                      <Typography>.NET</Typography>
                      <Typography>PHP</Typography>
                      <Typography>C++</Typography>
                      <Typography>Python</Typography>
                      <Typography>Golang</Typography>
                      <Typography>Node.js</Typography>
                      <Typography>Front-End</Typography>
                      <Typography>MEAN Stack</Typography>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
            <Typography variant="subtitle1" className=" underline">
              SOFTWARE DEVELOPMENT
            </Typography>
            <div className="lg:pt-8 pl-4 space-y-4">
            <Typography variant="body1">
              Software Product Development
            </Typography>
            <div className="pl-4 space-y-4">
              <Typography>MVP Development</Typography>
              <Typography>Startup Consulting</Typography>
              <Typography>Software Development for Startups</Typography>
              <Typography>How to Build a Software Startup</Typography>
              <Typography>SaaS Consulting</Typography>
              <Typography>SaaS Enhancement</Typography>
              <Typography>How to Build a SaaS Startup</Typography>
              <Typography>SaaS Development</Typography>
              <Typography>VR Development</Typography>
            </div>
          </div>
          <div className="lg:pt-8 pl-4 space-y-4">
            <Typography variant="body1">Technologies</Typography>
            <div className="pl-4 space-y-4">
              <Typography>Java</Typography>
              <Typography>.NET</Typography>
              <Typography>PHP</Typography>
              <Typography>C++</Typography>
              <Typography>Python</Typography>
              <Typography>Golang</Typography>
              <Typography>Node.js</Typography>
              <Typography>Front-End</Typography>
              <Typography>MEAN Stack</Typography>
            </div>
          </div>
            </div>
          )}

          
        </div>
        {page === 1 ? (
          <ServicePage1 title={title} />
        ) : (
          <ServicePage2 title={title} />
        )}
        {/* <ServicePage2/> */}
      </div>
      <Footer />
    </div>
  );
};

export default ServicesMainPage;
