import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Heading from "../components/Heading";
import Header from "../Home/Header/Header";
import { SolutionDetails } from "../assests/data";
import Industrycard from "../Home/OurIndustries/Industrycard";
import Footer from "../Home/Footer";

const ServicePage4 = ({ title }) => {
  const data = SolutionDetails[title];
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Header />
      <div className="p-4 lg:px-24 space-y-4">
        <Heading
          title="Selected Success Stories of Our 3,300-Project Portfolio"
          titleType={isMatched ? "h4" : "h3"}
          lineSize={20}
        />
        <div className="space-y-5">
          <Typography variant="body1">
            Since 2005, M2ITCare has been delivering software for health
            systems, outpatient clinics, hospitals, ancillary providers, life
            science companies and other healthcare organizations. Our technical
            solutions help providers ensure secure, reliable and informative
            patient-caregiver interaction.
          </Typography>
          <Typography variant="h6">
            Take a look at the real-world examples of software that improves
            care delivery, clinical testing and hospital management.
          </Typography>
          <Typography>
            Visit our healthcare software development page to find out more
            about our services and competences as well as order a{" "}
            <span className=" text-lg font-bold">free consultation</span> and
            PoC.
          </Typography>
        </div>
        <div className="space-y-10">
          {data.cards.map((item) => {
            return <Industrycard data={item} />;
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage4;
