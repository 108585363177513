import { Typography } from "@mui/material";
import React from "react";
import it1 from "../../assests/it1.png";
import it2 from "../../assests/it2.png";
import it3 from "../../assests/it3.png";
import it4 from "../../assests/it4.png";
import it5 from "../../assests/it5.png";
import it6 from "../../assests/it6.png";
import it7 from "../../assests/it7.png";
import it8 from "../../assests/it8.png";

const ItManagedSolution = () => {
  return (
    <div className="lg:p-16 p-2 py-5  bg-[#0B3155] space-y-10">
      <div className="text-center lg:px-32">
        <Typography variant="h4" className="text-white">
          Managed Services
        </Typography>
        <Typography variant="subtitle1" className="text-white">
          As a Managed Service Provider, Ventura Tech delivers reliable network,
          equipment and applications support to SF Bay Area businesses for a
          competitive monthly fee.
        </Typography>
      </div>

      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 lg:space-y-0 space-y-4">
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it1} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Workstation and Server Support
            </Typography>
            <Typography variant="body1" className="text-white">
              The Worry-Free Agent runs on every end user system & server. It
              monitors systems, runs routine maintenance, provides virus/spyware
              protection, updates system software and allows secure remote
              access for quick issue resolution.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it2} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Operating Systems
            </Typography>
            <Typography variant="body1" className="text-white">
              Experience with major OS versions, including Windows legacy, 7,
              8.1, 10, 11, Windows Server 2003, 2008, 2012, 2016, 2019 MacOS,
              and Unix.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it3} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Network Administration
            </Typography>
            <Typography variant="body1" className="text-white">
              Design, install and administer custom networks. We optimize
              internet service, configure firewall and security, setup and
              administer databases, setup and configure remote access and VPN
              solutions, and manage virtual servers.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it4} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Applications
            </Typography>
            <Typography variant="body1" className="text-white">
              Work with all major business applications including Microsoft
              Office 365, Adobe Creative Suite, OpenDNS, E-Mail Solutions,
              Security software, Engineering and Design software, Quickbooks,
              etc.
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:space-x-2 lg:space-y-0 space-y-4">
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it5} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Backup
            </Typography>
            <Typography variant="body1" className="text-white">
              Recommend, configure and maintain both local and hosted backup
              systems, a critical component of every business network.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it6} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Hosted Solutions
            </Typography>
            <Typography variant="body1" className="text-white">
              Support for popular hosted solutions such as G Suite, Office 365,
              (Azure, Sharepoint) Hosted Exchange, VOIP, Teleconferencing (ZOOM)
              and file sharing solutions.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it7} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Partners
            </Typography>
            <Typography variant="body1" className="text-white">
              Handle deployment and configuration of 3rd party solution
              providers including ISPs, phone providers, data cabling, and
              document management solutions.
            </Typography>
          </div>
        </div>
        <div className="lg:w-1/4 flex flex-col items-center space-y-4 border-x rounded-2xl p-2">
          <div className="w-48 h-40 self-center">
            <img src={it8} />
          </div>
          <div className="text-center space-y-4">
            <Typography variant="h5" className="text-white">
              Vendors
            </Typography>
            <Typography variant="body1" className="text-white">
              Manage hardware and software purchases, leveraging relationships
              to minimize headaches. Equipment partners like Cisco, Meraki,
              Dell, Apple, Sophos, etc
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItManagedSolution;
