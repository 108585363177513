import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WebIcon from "@mui/icons-material/Web";
import TerminalIcon from "@mui/icons-material/Terminal";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import StorageIcon from "@mui/icons-material/Storage";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import BusinessIcon from "@mui/icons-material/Business";
import FactoryIcon from "@mui/icons-material/Factory";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailIcon from "@mui/icons-material/Mail";

import { Link } from "react-router-dom";

const DrawerHeader = () => {
  const [toggleDrawer, setToggleDrawer] = useState(false);

  const [websiteSelected, setWebsiteSelected] = useState(false);
  const [softwareSelected, setSoftwareSelected] = useState(false);
  const [itSelected, setItSelected] = useState(false);
  const [mobileSelected, setMobileSelected] = useState(false);
  const [digitalMarkrtingSelected, setDigitalMarketingSelected] =
    useState(false);
  const [serverSelected, setServerSelected] = useState(false);
  return (
    <Box className="flex justify-between items-center">
      <div className="flex items-center">
        <IconButton onClick={() => setToggleDrawer(!toggleDrawer)}
        >
          <MenuIcon sx={{ color: "#FF3234", fontSize: 40 }} />
        </IconButton>
        <Typography
          variant="p"
          className="text-[#FF3234] text-2xl pb-0.5 font-bold cursor-pointer"
        >
          <Link to="/">M2-ITCARE</Link>
        </Typography>
        <Drawer open={toggleDrawer} onClose={() => setToggleDrawer(false)}>
          <Box
            sx={{
              width: "100%",
              minWidth: 360,
              bgcolor: "white",
              color: "black",
            }}
            className="p-6"
          >
            <Box className="flex justify-between items-center mb-10">
              <Typography
                variant="p"
                className="text-[#FF3234] text-2xl font-bold cursor-pointer"
              >
                <Link to="/">M2-ITCARE</Link>
              </Typography>
              <CloseIcon
                sx={{ color: "#FF3234", fontSize: 30 }}
                onClick={() => setToggleDrawer(false)}
              />
            </Box>
            <Box className="">
              <Typography variant="p" className="font-semibold text-lg">
                Services
              </Typography>
              <Divider />
              <List className="">
                <ListItem
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => setWebsiteSelected(!websiteSelected)}
                  >
                    <ListItemIcon>
                      <WebIcon sx={{ color: "#FF3234", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText primary="Website Development" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                  {websiteSelected && (
                    <>
                      <List>
                        <ListItem>
                          <Link to="/services/ecommerce-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <ShoppingCartIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Ecommerce Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/business-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <BusinessIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Business Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/news-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <NewspaperIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="News Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemIcon>
                              <FactoryIcon
                                sx={{ color: "#FF3234", fontSize: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Industries Website" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </ListItem>
                <ListItem
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => setSoftwareSelected(!softwareSelected)}
                  >
                    <ListItemIcon>
                      <TerminalIcon sx={{ color: "#FF3234", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText primary="Software Solution" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                  {softwareSelected && (
                    <>
                      <List>
                        <ListItem>
                          <Link to="/services/ecommerce-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <ShoppingCartIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Ecommerce Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/business-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <BusinessIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Business Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/news-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <NewspaperIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="News Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemIcon>
                              <FactoryIcon
                                sx={{ color: "#FF3234", fontSize: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Industries Website" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </ListItem>
                <ListItem
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => setItSelected(!itSelected)}
                  >
                    <ListItemIcon>
                      <ImportantDevicesIcon
                        sx={{ color: "#FF3234", fontSize: 30 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="IT Services" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                  {itSelected && (
                    <>
                      <List>
                        <ListItem>
                          <Link to="/services/ecommerce-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <ShoppingCartIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Ecommerce Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/business-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <BusinessIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Business Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/news-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <NewspaperIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="News Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemIcon>
                              <FactoryIcon
                                sx={{ color: "#FF3234", fontSize: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Industries Website" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </ListItem>
                <ListItem
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => setMobileSelected(!mobileSelected)}
                  >
                    <ListItemIcon>
                      <MobileScreenShareIcon
                        sx={{ color: "#FF3234", fontSize: 30 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Mobile Application" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                  {mobileSelected && (
                    <>
                      <List>
                        <ListItem>
                          <Link to="/services/ecommerce-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <ShoppingCartIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Ecommerce Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/business-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <BusinessIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Business Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/news-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <NewspaperIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="News Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemIcon>
                              <FactoryIcon
                                sx={{ color: "#FF3234", fontSize: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Industries Website" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </ListItem>
                <ListItem
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() =>
                      setDigitalMarketingSelected(!digitalMarkrtingSelected)
                    }
                  >
                    <ListItemIcon>
                      <SettingsVoiceIcon
                        sx={{ color: "#FF3234", fontSize: 30 }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Digital Marketing" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                  {digitalMarkrtingSelected && (
                    <>
                      <List>
                        <ListItem>
                          <Link to="/services/ecommerce-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <ShoppingCartIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Ecommerce Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/business-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <BusinessIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Business Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/news-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <NewspaperIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="News Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemIcon>
                              <FactoryIcon
                                sx={{ color: "#FF3234", fontSize: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Industries Website" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </ListItem>
                <ListItem
                  className="flex flex-col"
                  style={{ alignItems: "flex-start" }}
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => setServerSelected(!serverSelected)}
                  >
                    <ListItemIcon>
                      <StorageIcon sx={{ color: "#FF3234", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText primary="Server Solutions" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                  {serverSelected && (
                    <>
                      <List>
                        <ListItem>
                          <Link to="/services/ecommerce-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <ShoppingCartIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Ecommerce Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/business-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <BusinessIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="Business Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link to="/services/news-website">
                            <ListItemButton>
                              <ListItemIcon>
                                <NewspaperIcon
                                  sx={{ color: "#FF3234", fontSize: 25 }}
                                />
                              </ListItemIcon>
                              <ListItemText primary="News Website" />
                            </ListItemButton>
                          </Link>
                        </ListItem>
                        <ListItem>
                          <ListItemButton>
                            <ListItemIcon>
                              <FactoryIcon
                                sx={{ color: "#FF3234", fontSize: 25 }}
                              />
                            </ListItemIcon>
                            <ListItemText primary="Industries Website" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </>
                  )}
                </ListItem>
              </List>
            </Box>
            <Box className="">
              <Typography variant="p" className="font-semibold text-lg">
                Company
              </Typography>
              <Divider />
              <List>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonIcon sx={{ color: "#FF3234", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonIcon sx={{ color: "#FF3234", fontSize: 30 }} />
                    </ListItemIcon>
                    <ListItemText primary="Login" />
                    <KeyboardArrowDownIcon />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Drawer>

        {/* <Typography variant="p" className="text-xl text-[#FF3234] font-medium">
        Meu
      </Typography> */}
      </div>
      <div>
        <div className="flex items-center space-x-2">
          <div
            className=" rounded-full p-2 cursor-pointer"
            style={{
              boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
            }}
          >
            <a href="tel:+977-9807207940">
                      <LocalPhoneIcon color="secondary" />
                    </a>
          </div>
          <div
            className="border rounded-full p-2 cursor-pointer"
            style={{
              boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
            }}
          >
            <a href="https://wa.me/9779807207940">
                      <WhatsAppIcon color="success" />
                    </a>
          </div>
          <div
            className="border rounded-full p-2 cursor-pointer"
            style={{
              boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
            }}
          >
            <a href="https://www.facebook.com/m2itcare">
                      <FacebookIcon color="primary" />
                    </a>
          </div>
          <div
            className="border rounded-full p-2 cursor-pointer"
            style={{
              boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
            }}
          >
            <a href="mailto:manish@m2itcare.com">
                      <MailIcon color="error" />
                    </a>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default DrawerHeader;
