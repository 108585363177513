import React from "react";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import video from "../../assests/company.mp4";
import { useMediaQuery, useTheme } from "@mui/material";

const BannerVideo = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className={isMatched ? "h-80" : "h-screen bg-[#F2F1FB]"}>
      <Box
        component="ul"
        sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
        className="h-full"
      >
        <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
          <CardCover>
            <video
              autoPlay
              loop
              muted
              // poster="https://assets.codepen.io/6093409/river.jpg"
            >
              <source
                // src="https://assets.codepen.io/6093409/river.mp4"
                src={video}
                type="video/mp4"
              />
            </video>
          </CardCover>
          <CardContent
            className={
              isMatched
                ? "justify-end items-center text-white"
                : "justify-center items-center text-white"
            }
          >
            <Typography
              level="p"
              className={
                isMatched
                  ? "text-white text-xl font-semibold"
                  : "text-white text-5xl font-bold"
              }
            >
              We’re a nation-wide software engineering company
            </Typography>
            <Typography
              level="p"
              className={
                isMatched
                  ? "text-white text-2xl font-bold"
                  : "text-white text-4xl font-bold"
              }
            >
              making success stories for over 8 years
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default BannerVideo;
