import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Header from "../Home/Header/Header";
import services from "../assests/automotive.jpg";
import ServiceCard from "./ServiceCard";
import it7 from "../assests/it7.png";
import ServicesMainPage from "./ServicesMainPage";
import { ServicesDetailFormat1 } from "../assests/data";
const ServicePage1 = ({ title }) => {
  const data = ServicesDetailFormat1[title];

  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  // console.log(data.img)
  return (
    <>
      <div className="lg:w-3/4 w-full p-4 lg:p-8">
        <Typography variant={isMatched ? "h4" : "h3"}>
          {data.heading}
        </Typography>
        <div className="w-16 h-0.5 bg-[#EC8A00]"></div>
        <div className="mt-10">
          <img src={data.img[data.headingImageName]} />
        </div>
        <div
          className="flex flex-col lg:flex-row pt-10 p-4 justify-between mb-10"
          style={{
            boxShadow: "0 5px 8px 5px rgba(180, 180, 180, 0.5)",
          }}
        >
          {data.fiveSteps.map((item, index) => {
            return (
              <div className="space-y-4">
                {isMatched ? (
                  <>
                    <div>
                      <div className="flex items-center space-x-1">
                        <div className="w-1 h-1 rounded-full bg-gray-400"></div>
                        <Typography variant="subtitle2">{item}</Typography>
                      </div>
                      {index !== data.fiveSteps.length - 1 ? (
                        <div className="w-0.5 h-5 bg-gray-400"></div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center space-x-1">
                      <div className="w-1 h-1 rounded-full bg-gray-400"></div>
                      <div className="w-40 h-0.5 bg-gray-400"></div>
                    </div>
                    <Typography variant="body1">{item}</Typography>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Typography variant="subtitle1">{data.shortDescription}</Typography>
        <div className="mt-10 space-y-4">
          <div className="w-full flex flex-col lg:flex-row justify-between lg:space-x-2 space-y-4 lg:space-y-0">
            <ServiceCard
              image={
                data.cards.card[0].cardImage[data.cards.card[0].cardImageName]
              }
              heading={data.cards.card[0].cardHeading} 
              description={data.cards.card[0].cardDescription}
            />
            <ServiceCard
              image={it7}
              heading={"Fast time to market"}
              description={
                "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks)."
              }
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row justify-between lg:space-x-2 space-y-4 lg:space-y-0">
            <ServiceCard
              image={it7}
              heading={"Fast time to market"}
              description={
                "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks)."
              }
            />
            <ServiceCard
              image={it7}
              heading={"Fast time to market"}
              description={
                "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks)."
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage1;
