import { Divider, Typography } from "@mui/material";
import React from "react";
import it9 from "../../assests/it9.jpg";
import it10 from "../../assests/it10.jpg";
import it11 from "../../assests/it11.png";
import it12 from "../../assests/it12.png";

const ItDetails = () => {
  return (
    <div className="bg-gray-100 lg:p-16 p-4 lg:space-y-16 space-y-4">
      <div className="w-full flex flex-col lg:flex-row lg:px-20 items-center">
        <div className="lg:w-1/2 space-y-8">
          <Typography variant="h4" className="">
            Server and Network Setup Services
          </Typography>
          <div className="flex items-center ">
            <div className="w-12 h-0.5 bg-[#0B3155]"></div>
            <div className="w-3 h-3 rounded-full bg-[#0B3155]"></div>
          </div>
          <Typography variant="subtitle1" className="">
            When your network goes down, your business comes to a grinding halt.
            Servers and networks are the building blocks of your business – any
            interruptions can result in lost productivity and revenue. We
            understand the severity of server and network downtime. That’s why
            we guarantee uptimes of 99.99% all year round! IT Support Guys’
            network solutions cover network equipment issues, system failures,
            and application updates to ensure your business stays up and
            running.
          </Typography>
        </div>
        <div className="lg:p-8 mt-4">
          <img src={it9} style={{borderRadius: 20}} />
        </div>
      </div>
      <Divider />
      <div className="w-full flex flex-col lg:flex-row lg:px-20 items-center">
        <div className="lg:w-1/2 lg:p-8 mb-4">
          <img src={it10} style={{
            width:400,
            height: 300,
            borderRadius: 20,
          }}/>
        </div>
        <div className="lg:w-1/2 space-y-8">
          <Typography variant="h4" className="">
            Setups, Maintenance, and Upgrades
          </Typography>
          <div className="flex items-center ">
            <div className="w-12 h-0.5 bg-[#0B3155]"></div>
            <div className="w-3 h-3 rounded-full bg-[#0B3155]"></div>
          </div>
          <Typography variant="subtitle1" className="">
            All servers and networks require continuous attention to run
            smoothly. Our network and application monitoring processes ensure
            that your current technology is updated, fixed, and replaced as
            often as necessary. We not only complete regularly scheduled
            maintenance to ensure the longevity of your information technology
            but also optimize and manage the complete hardware lifecycle of your
            critical systems to gurantee you’ll receive the greatest ROI on each
            investment you make. Using the best available technology, we’ll help
            you meet your budget and long-term business goals with continual
            network improvements.
          </Typography>
        </div>
      </div>
      <Divider />
      <div className="w-full flex flex-col lg:flex-row lg:px-20 items-center">
        <div className="lg:w-1/2 space-y-8">
          <Typography variant="h4" className="">
            Firewall and Network Security
          </Typography>
          <div className="flex items-center ">
            <div className="w-12 h-0.5 bg-[#0B3155]"></div>
            <div className="w-3 h-3 rounded-full bg-[#0B3155]"></div>
          </div>
          <Typography variant="subtitle1" className="">
            Cybercrime is evolving by the hour – and you need to stay ahead of
            the risk factors. Our network security services include proactive
            controls like packet filtering, state inspections, proxies, and
            intrusion detection systems. With IT Support Guys’ help, you will
            wisely protect your most valuable asset: data.
          </Typography>
        </div>
        <div className="lg:p-8 mt-4">
          <img src={it11} style={{
            width:400,
            height: 300,
            borderRadius: 20,
          }} />
        </div>
      </div>
      <Divider />
      <div className="w-full flex flex-col lg:flex-row lg:px-20 items-center">
        <div className="lg:w-1/2 lg:p-8 mb-4">
          <img src={it12} style={{
            width:400,
            height: 300,
            borderRadius:20,
            
          }} />
        </div>
        <div className="lg:w-1/2 space-y-8">
          <Typography variant="h4" className="">
            Windows, Mac, and Cloud Server Administration
          </Typography>
          <div className="flex items-center ">
            <div className="w-12 h-0.5 bg-[#0B3155]"></div>
            <div className="w-3 h-3 rounded-full bg-[#0B3155]"></div>
          </div>
          <Typography variant="subtitle1" className="">
            Our experts will provide end-to-end support for your networks -
            regardless of your operating systems or business type. We’ll provide
            monthly health reports on all of your workstations and servers,
            allowing you to work with critical, real-time performance metrics
            for continuous improvement.
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ItDetails;
