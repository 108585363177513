import { ReactComponent as NetIcon } from "./technology/web/net.svg";
import { ReactComponent as NodeIcon } from "./technology/web/node.svg";
import { ReactComponent as PhpIcon } from "./technology/web/php.svg";
import { ReactComponent as GoIcon } from "./technology/web/go.svg";
import { ReactComponent as CppIcon } from "./technology/web/cpp.svg";
import { ReactComponent as JavaIcon } from "./technology/web/java.svg";
import { ReactComponent as PythonIcon } from "./technology/web/python.svg";
import { ReactComponent as HtmlIcon } from "./technology/web/html.svg";
import { ReactComponent as CssIcon } from "./technology/web/css.svg";
import { ReactComponent as JsIcon } from "./technology/web/js.svg";
import { ReactComponent as EmberIcon } from "./technology/web/ember.svg";
import { ReactComponent as MeteorIcon } from "./technology/web/meteor.svg";
import { ReactComponent as ReactIcon } from "./technology/web/react.svg";
import { ReactComponent as VueIcon } from "./technology/web/vue.svg";
import { ReactComponent as AngularIcon } from "./technology/web/angular.svg";

import { ReactComponent as IosIcon } from "./technology/mobile/ios.svg";
import { ReactComponent as AndroidIcon } from "./technology/mobile/android.svg";
import { ReactComponent as ReactNativeIcon } from "./technology/mobile/react.svg";
import { ReactComponent as FlutterIcon } from "./technology/mobile/flutter.svg";
import { ReactComponent as JsMobileIcon } from "./technology/mobile/js.svg";
import { ReactComponent as JavaMobileIcon } from "./technology/mobile/java.svg";

import { ReactComponent as CppDesktopIcon } from "./technology/desktop/cpp.svg";
import { ReactComponent as CsharpIcon } from "./technology/desktop/csharp.svg";
import { ReactComponent as PythonDesktopIcon } from "./technology/desktop/python.svg";
import { ReactComponent as SwiftIcon } from "./technology/desktop/swift.svg";

import { ReactComponent as CsharpPlatformIcon } from "./technology/platform/csharp.svg";
import { ReactComponent as JavaPlatformIcon } from "./technology/platform/java.svg";
import { ReactComponent as NetPlatformIcon } from "./technology/platform/net.svg";
import { ReactComponent as PythonPlatformIcon } from "./technology/platform/python.svg";

import { ReactComponent as AwsIcon } from "./technology/database/aws.svg";
import { ReactComponent as MicrosoftSqlIcon } from "./technology/database/microsoft-sql.svg";
import { ReactComponent as OracleIcon } from "./technology/database/oracle.svg";

import { ReactComponent as CassandraIcon } from "./technology/bigdata/cassandra.svg";
import { ReactComponent as HiveIcon } from "./technology/bigdata/hive.svg";
import { ReactComponent as MongodbIcon } from "./technology/bigdata/mongodb.svg";
import { ReactComponent as HadoopIcon } from "./technology/bigdata/hadoop.svg";

import { ReactComponent as AwsCloudIcon } from "./technology/cloud/aws.svg";
import { ReactComponent as AzureCloudIcon } from "./technology/cloud/azure.svg";
import { ReactComponent as GoogleCloudIcon } from "./technology/cloud/google-cloud.svg";

import { ReactComponent as PythonAIIcon } from "./technology/ai/python.svg";
import { ReactComponent as TensorflowAIIcon } from "./technology/ai/tensorflow.svg";

import { ReactComponent as CppMLIcon } from "./technology/ml/cpp.svg";
import { ReactComponent as MatlabMLIcon } from "./technology/ml/matlab.svg";
import { ReactComponent as PythonMLIcon } from "./technology/ml/python.svg";
import { ReactComponent as TensorflowMLIcon } from "./technology/ml/tensorflow.svg";

import { ReactComponent as DockerIcon } from "./technology/devops/docker.svg";
import { ReactComponent as KubernetesIcon } from "./technology/devops/kubernetes.svg";


// Website Development
import ecommerceMain from "../assests/details/website/ecommerce-main.png";
import automotive from "../assests/automotive.jpg";
import itservice from "../assests/itservice.jpg";
import it6 from "../assests/it6.png";
import it7 from "../assests/it6.png";
import it9 from "../assests/it9.jpg";

export const WhyUs = [
  "33 years of experience in information technology.",
  "15-year experience in help desk and application support.",
  "14 years of experience in ITIL-compliant IT service management.",
  "9 Microsoft Gold Competencies, including Application Development and Integration, Data Platform, Data Analytics, Collaboration and Content, etc.",
  "AWS Select Services Partner, IBM Business Partner.",
  "700 + professionals on board.",
  "Ability to bring value in every project, as proved by the prestigious Global Outsourcing 100 listing.",
  "Mature quality management system confirmed by the ISO 9001 certification allowing to implement projects fully meeting customers' quality, time, and budget expectations.",
  "Assured safety of the customers’ data we access during the cooperation proved by ISO 27001 certificate.",
  "Broad time zones coverage: from GMT -9 in North America to GMT +13 in New Zealand.",
];

export const TechnologyIcon = {
  web: {
    backEnd: [
      NetIcon,
      NodeIcon,
      PhpIcon,
      GoIcon,
      CppIcon,
      JavaIcon,
      PythonIcon,
    ],
    frontEnd: [
      HtmlIcon,
      CssIcon,
      JsIcon,
      EmberIcon,
      MeteorIcon,
      ReactIcon,
      AngularIcon,
      VueIcon,
    ],
  },
  Mobile: [
    IosIcon,
    AndroidIcon,
    ReactNativeIcon,
    FlutterIcon,
    JsMobileIcon,
    JavaMobileIcon,
  ],
  Desktop: [CppDesktopIcon, CsharpIcon, SwiftIcon, PythonDesktopIcon],
  Platform: [
    JavaPlatformIcon,
    PythonPlatformIcon,
    NetPlatformIcon,
    CsharpPlatformIcon,
  ],
  Database: [AwsIcon, MicrosoftSqlIcon, OracleIcon],
  Bigdata: [HadoopIcon, HiveIcon, CassandraIcon, MongodbIcon],
  Cloud: [AwsCloudIcon, AzureCloudIcon, GoogleCloudIcon],
  "Artificial Intelligence": [PythonAIIcon, TensorflowAIIcon],
  "Machine Learning": [CppMLIcon, MatlabMLIcon, PythonMLIcon, TensorflowMLIcon],
  Devops: [DockerIcon, KubernetesIcon],
};

export const OurOtherOffering = {
  "Software Development": {
    description:
      "A software development company with 33 years of business excellence, we can develop reliable, scalable and secure software solutions for any OS, browser and device. We bring together deep industry expertise and the latest IT advancements to deliver custom solutions and products that perfectly fit the needs and behavior of their users.",
    infoPoints: [
      "Software consulting",
      "Custom software development",
      "Software development outsourcing",
      "Software product development",
      "Team augmentation",
      "Cloud application development",
      "Legacy software modernization",
    ],
  },
  "Testing & QA": {
    description:
      "We offer full-range QA and testing outsourcing services, can help to develop your QA or enhance the existing one, assist you in TCoE setup and evolution. We perform end-to-end testing of mobile, web and desktop application at each stage of the development lifecycle.",
    infoPoints: [
      "QA outsourcing",
      "QA consulting",
      "Security testing",
      "Functional testing",
      "Usability testing",
      "Performance testing",
      "Test automation",
    ],
  },
  "Application Services": {
    description:
      "Our experts help mid-sized and large firms build, test, protect, manage, migrate and optimize digital solutions ensuring they’re always up and running and achieve the optimal TCO.",
    infoPoints: [
      "Application management",
      "Application modernization",
      "Application integration",
      "Application security services",
      "Application development",
      "Application testing",
      "Application maintenance and support",
    ],
  },
  "UX/UI Design": {
    description:
      "User experience and user interface design for all types of websites, SaaS, and web/mobile apps. We combine the latest UI/UX trends with our customers’ individual goals and needs to deliver intuitive, vibrant, and impactful designs that power up businesses.",
    infoPoints: [
      "User Interface (UI) Design",
      "Responsive Web App Design",
      "Software-as-as-Service (SaaS) UI Design",
      "Ecommerce Website Design",
      "User Experience (UX) Design",
      "Website Redesign",
      "Responsive Web Design",
    ],
  },
  "IT Consulting": {
    description:
      "Our experts can help to develop and implement an effective IT strategy, assist in smooth digital transformation and system integration as well as advise on improvements to your digital customer experience.",
    infoPoints: [
      "Digital Transformation Consulting",
      "Project Management Consulting",
      "Digital Crisis Management Consulting",
      "IT Service Management Consulting",
      "Solution consulting",
      "Platform consulting",
      "Enterprise IT consulting",
    ],
  },
  "Data Analytics": {
    description:
      "We support businesses in achieving fact-based decision-making by converting their historical and real-time, traditional and big data into actionable insights. Our services are tailored to make the raw data and the environment ready, as well as strengthen the business with advanced analytics capabilities.",
    infoPoints: [
      "Business Intelligence",
      "Big Data",
      "Data Warehousing",
      "Data Science",
      "Data Management",
      "Machine and Deep Learning",
      "Data Analytics as a Service",
    ],
  },
  "Help Desk Services": {
    description:
      "Help desk services for your IT environment or software products. We take on solving diverse issues from answering application functionality questions to performing fixes and enhancements on the code level for improved adoption of software, its smooth functioning and increased end user satisfaction.",
    infoPoints: [
      "Help desk outsourcing",
      "IT help desk services",
      "Outsourced help desk for MSP",
    ],
  },
  "Infrastructure Services": {
    description:
      "We apply our 12-year experience to offer a full set of infrastructure services. Being ISO 27001 certified, we guarantee that cooperation with us does not pose any risks to our customers’ data security.",
    infoPoints: [
      "Managed IT support",
      "Data center support and management",
      "Cloud consulting and management",
      "DevOps consulting and implementation",
      "Virtual desktop consulting, implementation and support",
      "Networking management services",
    ],
  },
  "Cybersecurity Services": {
    description:
      "Equipped with 19-year experience in information security and employing ISO 27001 certified information security management practices, we help to achieve the robust protection of the companies’ applications and networks.",
    infoPoints: [
      "Managed security services",
      "Compliance testing",
      "Information security consulting",
      "Security code review",
      "Application security implementation",
      "Vulnerability assessment and penetration testing",
    ],
  },
};

export const ServicesDetailFormat1 = {
  "ecommerce-website": {
    heading: "Ecommerce Website",
    img: { ecommerceMain },
    headingImageName: "ecommerceMain",
    fiveSteps: [
      "UX/UI Design",
      "FrontEnd Design",
      "BackEnd Design",
      "Merging",
      "Ecommerce Product",
    ],
    shortDescription:
      "We help to create marketable commercial ecommerce website for business users or individual consumers. M2Itcare provides outsourced product development services to design, architect and implement user-friendly and engaging software products.",
    cards: {
      cardMainHeading: "What You Get with IT Support Services",
      card: [
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
      ],
    },
  },
  "news-website": {
    heading: "News Website",
    img: { automotive },
    headingImageName: "automotive",
    fiveSteps: [
      "UX/UI Design",
      "FrontEnd Design",
      "BackEnd Design",
      "Merging",
      "Ecommerce Product",
    ],
    shortDescription:
      "Software product development helps create marketable commercial software for business users or individual consumers. M2ITCare provides outsourced product development services to design, architect and implement user-friendly and engaging software products.",
    cards: {
      cardMainHeading: "What You Get with IT Support Services",
      card: [
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
        {
          cardImage: { it6 },
          cardImageName: "it6",
          cardHeading: "Fast time to market",
          cardDescription:
            "With Agile development, CI/CD pipeline, DevOps approach, and a balanced mix of manual and automated testing, we can introduce needed product changes while maintaining a steady release pace (every 2-3 weeks).",
        },
      ],
    },
  },
};

export const ServicesDetailFormat2 = {
  "business-website": {
    heading: "Business Website",
    img: { itservice },
    headingImageName: "itservice",
    fiveSteps: [
      "UX/UI Design",
      "FrontEnd Design",
      "BackEnd Design",
      "Merging",
      "Business Product",
    ],
    shortDescription:
      "Software product development helps create marketable commercial software for business users or individual consumers. M2ITCare provides outsourced product development services to design, architect and implement user-friendly and engaging software products.",
    miniCard: {
      heading: "Want to Get Result-Oriented IT Support?",
      description:
        "M2ITCare’s IT support team will ensure continuous monitoring and timely resolution of detected and reported incidents to increase the efficiency of your IT infrastructure.",
      buttonText: "IT Support",
    },
    cards: {
      cardMainHeading: "What You Get with IT Support Services",
      card: [
        {
          cardImage: {},
          cardHeading: "Multi-tier incident resolution pipeline:",
          cardDescriptionList: [
            "L1. A user support team.",
            "L2. A technical support team.",
            "L3. A team of software engineers.",
          ],
        },
        {
          cardImage: {},
          cardHeading: "Multi-tier incident resolution pipeline:",
          cardDescriptionList: [
            "L1. A user support team.",
            "L2. A technical support team.",
            "L3. A team of software engineers.",
          ],
        },
        {
          cardImage: {},
          cardHeading: "Multi-tier incident resolution pipeline:",
          cardDescriptionList: [
            "L1. A user support team.",
            "L2. A technical support team.",
            "L3. A team of software engineers.",
          ],
        },
        {
          cardImage: {},
          cardHeading: "Multi-tier incident resolution pipeline:",
          cardDescriptionList: [
            "L1. A user support team.",
            "L2. A technical support team.",
            "L3. A team of software engineers.",
          ],
        },
        {
          cardImage: {},
          cardHeading: "Multi-tier incident resolution pipeline:",
          cardDescriptionList: [
            "L1. A user support team.",
            "L2. A technical support team.",
            "L3. A team of software engineers.",
          ],
        },
        {
          cardImage: {},
          cardHeading: "Multi-tier incident resolution pipeline:",
          cardDescriptionList: [
            "L1. A user support team.",
            "L2. A technical support team.",
            "L3. A team of software engineers.",
          ],
        },
      ],
    },
  },
};

// Industries Details

export const IndustriesDetails = {
  Healthcare: {
    title: "Healthcare",
    cards: [
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading: "BI Implementation for 200 Healthcare Centers",
        cardDescription:
          "M2ITCare created software to help 200 US healthcare centers and retirement homes to process patient and medication data. Solution consists of Java application for management and reporting along with an analytical data warehouse to consolidate information from 200 databases.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading:
          "Custom Application Development to Drive Digital Transformation across Phytotherapeutic Business",
        cardDescription:
          "M2ITCare automated paper-based document management, order and warehouse management workflows with custom software solutions. The delivered solutions have largely catalyzed customer experience optimization.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading: "Development of a Physiotherapy Platform for AKLOS Health",
        cardDescription:
          "M2ITCare helped AKLOS Health, an innovative healthcare startup, deliver a wearable-based physiotherapy platform with a mobile app and a web app. The apps use data from Xsens DOT wearable sensors to measure the motion range of patients’ joints and assess the therapy progress.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading:
          "Development of Automation Software for Cancer Drug & TPN Order and Manufacturing",
        cardDescription:
          "The GAMP4-compliant solution for order and manufacturing automation for a multinational pharmaceutical company with 80+ years of experience and $15+ bln revenue. This complex system covers medicine ordering and production, stock control, sales, reporting and billing.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading:
          "Development and Support of Software Products for Life Science Companies",
        cardDescription:
          "For over 10 years, a scalable team of 8-29 M2ITCare’s specialists provides development and support services for a provider of biotechnology, drug discovery and chemical research services. Currently, our team covers such directions as services, support, development and testing.",
      },
    ],
  },
  Banking: {
    title: "Banking",
    cards: [
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading: "BI Implementation for 200 Healthcare Centers",
        cardDescription:
          "M2ITCare created software to help 200 US healthcare centers and retirement homes to process patient and medication data. Solution consists of Java application for management and reporting along with an analytical data warehouse to consolidate information from 200 databases.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading:
          "Custom Application Development to Drive Digital Transformation across Phytotherapeutic Business",
        cardDescription:
          "M2ITCare automated paper-based document management, order and warehouse management workflows with custom software solutions. The delivered solutions have largely catalyzed customer experience optimization.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading: "Development of a Physiotherapy Platform for AKLOS Health",
        cardDescription:
          "M2ITCare helped AKLOS Health, an innovative healthcare startup, deliver a wearable-based physiotherapy platform with a mobile app and a web app. The apps use data from Xsens DOT wearable sensors to measure the motion range of patients’ joints and assess the therapy progress.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading:
          "Development of Automation Software for Cancer Drug & TPN Order and Manufacturing",
        cardDescription:
          "The GAMP4-compliant solution for order and manufacturing automation for a multinational pharmaceutical company with 80+ years of experience and $15+ bln revenue. This complex system covers medicine ordering and production, stock control, sales, reporting and billing.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading:
          "Development and Support of Software Products for Life Science Companies",
        cardDescription:
          "For over 10 years, a scalable team of 8-29 M2ITCare’s specialists provides development and support services for a provider of biotechnology, drug discovery and chemical research services. Currently, our team covers such directions as services, support, development and testing.",
      },
    ],
  },
};

// Our Solutions
export const SolutionDetails = {
  "Enterprise applications": {
    title: "Enterprise applications",
    cards: [
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading: "BI Implementation for 200 Healthcare Centers",
        cardDescription:
          "M2ITCare created software to help 200 US healthcare centers and retirement homes to process patient and medication data. Solution consists of Java application for management and reporting along with an analytical data warehouse to consolidate information from 200 databases.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading:
          "Custom Application Development to Drive Digital Transformation across Phytotherapeutic Business",
        cardDescription:
          "M2ITCare automated paper-based document management, order and warehouse management workflows with custom software solutions. The delivered solutions have largely catalyzed customer experience optimization.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading: "Development of a Physiotherapy Platform for AKLOS Health",
        cardDescription:
          "M2ITCare helped AKLOS Health, an innovative healthcare startup, deliver a wearable-based physiotherapy platform with a mobile app and a web app. The apps use data from Xsens DOT wearable sensors to measure the motion range of patients’ joints and assess the therapy progress.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading:
          "Development of Automation Software for Cancer Drug & TPN Order and Manufacturing",
        cardDescription:
          "The GAMP4-compliant solution for order and manufacturing automation for a multinational pharmaceutical company with 80+ years of experience and $15+ bln revenue. This complex system covers medicine ordering and production, stock control, sales, reporting and billing.",
      },
      {
        cardImage: { it6 },
        cardImageName: "it6",
        cardHeading:
          "Development and Support of Software Products for Life Science Companies",
        cardDescription:
          "For over 10 years, a scalable team of 8-29 M2ITCare’s specialists provides development and support services for a provider of biotechnology, drug discovery and chemical research services. Currently, our team covers such directions as services, support, development and testing.",
      },
    ],
  },
  ERP: {
    title: "ERP",
    cards: [
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading: "BI Implementation for 200 Healthcare Centers",
        cardDescription:
          "M2ITCare created software to help 200 US healthcare centers and retirement homes to process patient and medication data. Solution consists of Java application for management and reporting along with an analytical data warehouse to consolidate information from 200 databases.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading:
          "Custom Application Development to Drive Digital Transformation across Phytotherapeutic Business",
        cardDescription:
          "M2ITCare automated paper-based document management, order and warehouse management workflows with custom software solutions. The delivered solutions have largely catalyzed customer experience optimization.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading: "Development of a Physiotherapy Platform for AKLOS Health",
        cardDescription:
          "M2ITCare helped AKLOS Health, an innovative healthcare startup, deliver a wearable-based physiotherapy platform with a mobile app and a web app. The apps use data from Xsens DOT wearable sensors to measure the motion range of patients’ joints and assess the therapy progress.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading:
          "Development of Automation Software for Cancer Drug & TPN Order and Manufacturing",
        cardDescription:
          "The GAMP4-compliant solution for order and manufacturing automation for a multinational pharmaceutical company with 80+ years of experience and $15+ bln revenue. This complex system covers medicine ordering and production, stock control, sales, reporting and billing.",
      },
      {
        cardImage: { it9 },
        cardImageName: "it9",
        cardHeading:
          "Development and Support of Software Products for Life Science Companies",
        cardDescription:
          "For over 10 years, a scalable team of 8-29 M2ITCare’s specialists provides development and support services for a provider of biotechnology, drug discovery and chemical research services. Currently, our team covers such directions as services, support, development and testing.",
      },
    ],
  },
};


