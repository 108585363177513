import React, { useRef, useState } from "react";
import {
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Icon,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import { AiOutlineDown } from "react-icons/ai";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailIcon from "@mui/icons-material/Mail";
import DrawerHeader from "./DrawerHeader";
import { Link } from "react-router-dom";

const Header = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  const bottomRef = useRef(null);

  const [websiteEl, setWebsiteEl] = React.useState(null);
  const [websiteHovering, setWebsiteHovering] = React.useState(false);
  const [softwareEl, seSoftwareEl] = React.useState(null);
  const [itEl, setItEl] = React.useState(null);
  const [mobileEl, setMobileEl] = React.useState(null);
  const [digitalEl, setDigitalEl] = React.useState(null);
  const [serverEl, setServerEl] = React.useState(null);

  const openWebsite = Boolean(websiteEl);
  const openSoftware = Boolean(softwareEl);
  const openIt = Boolean(itEl);
  const openMobile = Boolean(mobileEl);
  const openDigital = Boolean(digitalEl);
  const openServer = Boolean(serverEl);

  const handleAssociates = () => {
    const element = document.getElementById("associates");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handleServices = () => {
    const element = document.getElementById("services");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handleSolutions = () => {
    const element = document.getElementById("solutions");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handleTechnology = () => {
    const element = document.getElementById("technology");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handleIndustries = () => {
    const element = document.getElementById("industries");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handlePricing = () => {
    const element = document.getElementById("pricing");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handleAboutUs = () => {
    const element = document.getElementById("aboutus");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const handleWebsiteClick = (event) => {
    setWebsiteEl(event.currentTarget);
    setWebsiteHovering(true);
  };
  const handleWebsiteClose = () => {
    setWebsiteEl(null);
    setWebsiteHovering(false);
  };
  const handleSoftwareClick = (event) => {
    seSoftwareEl(event.currentTarget);
  };
  const handleSoftwareClose = () => {
    seSoftwareEl(null);
  };
  const handleItClick = (event) => {
    setItEl(event.currentTarget);
  };
  const handleItClose = () => {
    setItEl(null);
  };
  const handleMobileClick = (event) => {
    setMobileEl(event.currentTarget);
  };
  const handleMobileClose = () => {
    setMobileEl(null);
  };
  const handleDigitalClick = (event) => {
    setDigitalEl(event.currentTarget);
  };
  const handleDigitalClose = () => {
    setDigitalEl(null);
  };
  const handleServerClick = (event) => {
    setServerEl(event.currentTarget);
  };
  const handleServerClose = () => {
    setServerEl(null);
  };
  return (
    <div
      className="sticky bg-white"
      style={{
        boxShadow: "0 0 10px 0 rgba(180, 180, 180, .5)",
      }}
    >
      {isMatched ? (
        <Box className="py-5 px-1 ">
          <DrawerHeader />
        </Box>
      ) : (
        <Box className="mx-24 py-4 space-y-4">
          {/* {digitalActive &&<> <div className="absolute h-96 w-96 bg-black z-1000 top-5 flex items-center justify-center space-x-2">
                <Typography
                  variant="p"
                  className="font-medium text-base text-gray-700"
                >
                  Digital Product Engineering List
                </Typography>
              </div>
              </>} */}
          <div className="flex justify-between items-center">
            <div className="flex justify-between items-center space-x-10">
              <Typography
                variant="p"
                className="text-[#FF3234] text-2xl font-bold cursor-pointer"
              >
                <Link to="/">M2-ITCARE</Link>
              </Typography>
              <div className="flex justify-between items-center space-x-8">
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handleAssociates}
                >
                  Associates
                </Typography>
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handleServices}
                >
                  Our Services
                </Typography>
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handleSolutions}
                >
                  Solutions
                </Typography>
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handleTechnology}
                >
                  Technology
                </Typography>
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handleIndustries}
                >
                  Industries
                </Typography>
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handlePricing}
                >
                  Pricing
                </Typography>
                <Typography
                  variant="p"
                  className="text-sm font-medium text-gray-400 cursor-pointer"
                  onClick={handleAboutUs}
                >
                  About Us
                </Typography>
              </div>
            </div>
            {/* <div className="">
              <Button variant="contained" color="error">
                Contact us
              </Button>
            </div> */}
            <div className="flex space-x-4">
              <div className="flex items-center">
                <LocalPhoneIcon color="primary" size="large" />
                <div>
                  <Typography variant="subtitle2">+977-9802074046</Typography>
                  <Typography variant="subtitle2">+977-9807207940</Typography>
                </div>
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <div
                    className=" rounded-full p-2 cursor-pointer"
                    style={{
                      boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
                    }}
                    
                  >
                    <a href="tel:+977-9807207940">
                      <LocalPhoneIcon color="secondary" />
                    </a>
                  </div>
                  <div
                    className="border rounded-full p-2 cursor-pointer"
                    style={{
                      boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
                    }}
                  >
                    <a href="https://wa.me/9779807207940">
                      <WhatsAppIcon color="success" />
                    </a>
                  </div>
                  <div
                    className="border rounded-full p-2 cursor-pointer"
                    style={{
                      boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
                    }}
                  >
                    <a href="https://www.facebook.com/m2itcare">
                      <FacebookIcon color="primary" />
                    </a>
                  </div>
                  <div
                    className="border rounded-full p-2 cursor-pointer"
                    style={{
                      boxShadow: "0 0 8px 1px rgba(150, 150, 150,0.5)",
                    }}
                  >
                    <a href="mailto:manish@m2itcare.com">
                      <MailIcon color="error" />
                    </a>
                  </div>
                </div>
                {/* <Typography>manish@m2itcare.com</Typography> */}
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-5">
              <div>
                <div
                  className="flex items-center justify-center space-x-2 cursor-pointer"
                  onMouseOver={handleWebsiteClick}
                  //  onMouseOut={handleWebsiteClose}
                  // onClick={handleWebsiteClick}
                  // onMouse={handleWebsiteClose}
                >
                  <Typography
                    variant="p"
                    className="font-medium text-base text-gray-700"
                  >
                    Website Development
                  </Typography>
                  <AiOutlineDown size="12" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={websiteEl}
                  open={openWebsite}
                  onClose={handleWebsiteClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="w-96"
                >
                  <MenuItem>
                    <Link to="/services/ecommerce-website">
                      Ecommerce Website
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/services/business-website">
                      Business Website
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/services/news-website">News Website</Link>
                  </MenuItem>
                  <MenuItem>Industries Website</MenuItem>
                </Menu>
              </div>
              <div>
                <div
                  className="flex items-center justify-center space-x-2 cursor-pointer"
                  onMouseOver={handleSoftwareClick}
                  //  onMouseLeave={handleClose}
                  // onClick={handleClick}
                  // onMouseOver={handleClick}
                >
                  <Typography
                    variant="p"
                    className="font-medium text-base text-gray-700"
                  >
                    Software Solutions
                  </Typography>
                  <AiOutlineDown size="12" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={softwareEl}
                  open={openSoftware}
                  onClose={handleSoftwareClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="w-96"
                >
                  <MenuItem>Software Product Development </MenuItem>
                  <MenuItem>Software for Startups</MenuItem>
                  <MenuItem>Mantainance and Support</MenuItem>
                  <MenuItem>Custom Software Development</MenuItem>
                  <MenuItem>Software Consulting</MenuItem>
                </Menu>
              </div>
              <div>
                <div
                  className="flex items-center justify-center space-x-2 cursor-pointer"
                  onMouseOver={handleItClick}
                  //  onMouseLeave={handleClose}
                  // onClick={handleClick}
                >
                  <Typography
                    variant="p"
                    className="font-medium text-base text-gray-700"
                  >
                    IT Services
                  </Typography>
                  <AiOutlineDown size="12" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={itEl}
                  open={openIt}
                  onClose={handleItClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="w-96"
                >
                  <MenuItem>
                    <Link to="/services">Managed Software Development</Link>
                  </MenuItem>
                  <MenuItem>Managed and Unlimited IT Support</MenuItem>
                  <MenuItem>PC & Mac Support</MenuItem>
                  <MenuItem>Managed Application Services</MenuItem>
                  <MenuItem>AWS Managed Services</MenuItem>
                  <MenuItem>Managed Security Services</MenuItem>
                  <MenuItem>Digital Transformation</MenuItem>
                  <MenuItem>CCTV Camera Installation</MenuItem>
                  <MenuItem>Printer related Support</MenuItem>
                  <MenuItem>IT Outsourcing</MenuItem>
                  <MenuItem>IT Office Relocation</MenuItem>
                  <MenuItem>Professional Services</MenuItem>
                  <MenuItem>IT Consulting Services</MenuItem>
                  <MenuItem>24/7 Support</MenuItem>
                </Menu>
              </div>
              <div>
                <div
                  className="flex items-center justify-center space-x-2 cursor-pointer"
                  onMouseOver={handleMobileClick}
                  //  onMouseLeave={handleClose}
                  // onClick={handleClick}
                >
                  <Typography
                    variant="p"
                    className="font-medium text-base text-gray-700"
                  >
                    Mobile Application
                  </Typography>
                  <AiOutlineDown size="12" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={mobileEl}
                  open={openMobile}
                  onClose={handleMobileClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="w-96"
                >
                  <MenuItem>Mobile App</MenuItem>
                  <MenuItem>React Native App</MenuItem>
                  <MenuItem>Flutter App</MenuItem>
                  <MenuItem>Hybrid App</MenuItem>
                  <MenuItem>Xamarian</MenuItem>
                  <MenuItem>IOS App</MenuItem>
                  <MenuItem>Android App</MenuItem>
                </Menu>
              </div>
              <div>
                <div
                  className="flex items-center justify-center space-x-2 cursor-pointer"
                  onMouseOver={handleDigitalClick}
                  //  onMouseLeave={handleClose}
                  // onClick={handleClick}
                >
                  <Typography
                    variant="p"
                    className="font-medium text-base text-gray-700"
                  >
                    Digital Marketing
                  </Typography>
                  <AiOutlineDown size="12" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={digitalEl}
                  open={openDigital}
                  onClose={handleDigitalClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="w-96"
                >
                  <MenuItem>Search Engine Optimization (SEO)</MenuItem>
                  <MenuItem>Local SEO</MenuItem>
                  <MenuItem>Technical SEO</MenuItem>
                  <MenuItem>Digital Marketing Strategy</MenuItem>
                  <MenuItem>Pay Per Click (PPC) Marketing</MenuItem>
                  <MenuItem>Link Building Services</MenuItem>
                  <MenuItem>Google Analytics Service</MenuItem>
                  <MenuItem>Conversion Rate Optimization</MenuItem>
                  <MenuItem>White Label Services</MenuItem>
                  <MenuItem>Franchise SEO</MenuItem>
                  <MenuItem>Search Engine Marketing (SEM)</MenuItem>
                </Menu>
              </div>
              <div>
                <div
                  className="flex items-center justify-center space-x-2 cursor-pointer"
                  onMouseOver={handleServerClick}
                  //  onMouseLeave={handleClose}
                  // onClick={handleClick}
                >
                  <Typography
                    variant="p"
                    className="font-medium text-base text-gray-700"
                  >
                    Server Solutions
                  </Typography>
                  <AiOutlineDown size="12" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={serverEl}
                  open={openServer}
                  onClose={handleServerClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="w-96"
                >
                  <MenuItem>Cloud Solutions</MenuItem>
                  <MenuItem>Compliance Solutions</MenuItem>
                  <MenuItem>Server & Network Support</MenuItem>
                  <MenuItem>IT Strategy Planning & Virtual CIO</MenuItem>
                  <MenuItem>Backup & Disaster Recovery</MenuItem>
                  <MenuItem>App Hosting / Setup</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default Header;
