import { Typography } from "@mui/material";
import React from "react";

const ServiceCard = ({ image, heading, description }) => {
  return (
    <div className="lg:w-1/2 border">
      <div className="w-full h-3 bg-[#005EB8]"></div>
      <div className="flex justify-center p-1">
        <img src={image} style={{ width: 150, height: 150 }} />
      </div>
      <div className="lg:p-10 p-4 flex flex-col items-center space-y-2">
        <Typography variant="h5">{heading}</Typography>
        <Typography variant="body1">{description}</Typography>
      </div>
    </div>
  );
};

export default ServiceCard;
