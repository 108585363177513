import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Header from "../Home/Header/Header";
import services from "../assests/automotive.jpg";
import ServiceCard from "./ServiceCard";
import it7 from "../assests/it7.png";
import itservice from "../assests/itservice.jpg";
import ServicesMainPage from "./ServicesMainPage";
import Heading from "../components/Heading";
import ServiceListCard from "./ServiceListCard";
import { ServicesDetailFormat2, WhyUs } from "../assests/data";

const ServicePage2 = ({ title }) => {
  const data = ServicesDetailFormat2[title];
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div className="lg:w-3/4 w-full p-4 lg:p-8">
        <Typography variant={isMatched ? "h4" : "h3"}>
          {data.heading}
        </Typography>
        <div className="w-16 h-0.5 bg-[#EC8A00]"></div>
        <div className="mt-10">
          <img src={data.img[data.headingImageName]} />
        </div>
        <div
          className="flex flex-col lg:flex-row pt-10 p-4 justify-between mb-10"
          style={{
            boxShadow: "0 5px 8px 5px rgba(180, 180, 180, 0.5)",
          }}
        >
          {data.fiveSteps.map((item, index) => {
            return (
              <div className="space-y-4">
                {isMatched ? (
                  <>
                    <div>
                      <div className="flex items-center space-x-1">
                        <div className="w-1 h-1 rounded-full bg-gray-400"></div>
                        <Typography variant="subtitle2">{item}</Typography>
                      </div>
                      {index !== data.fiveSteps.length - 1 ? (
                        <div className="w-0.5 h-5 bg-gray-400"></div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex items-center space-x-1">
                      <div className="w-1 h-1 rounded-full bg-gray-400"></div>
                      <div className="w-40 h-0.5 bg-gray-400"></div>
                    </div>
                    <Typography variant="body1">{item}</Typography>
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Typography variant="subtitle1">{data.shortDescription}</Typography>
        <div
          className="p-4 my-4"
          style={{
            boxShadow: "0 0 5px 5px rgba(180, 180, 180, 0.5)",
          }}
        >
          <Heading
            title={data.miniCard.heading}
            titleType={isMatched ? "h5" : "h4"}
            lineSize={20}
          />
          <div className="flex flex-col lg:flex-row mt-4">
            <Typography variant="subtitle1" className="lg:w-3/4">
              {data.miniCard.description}
            </Typography>
            <div className="self-end">
              <Button variant="contained" color="primary" size="small">
                {data.miniCard.buttonText}
              </Button>
            </div>
          </div>
        </div>
        <div className="mt-10 space-y-4">
          <Heading
            title={data.cards.cardMainHeading}
            titleType={isMatched ? "h5" : "h4"}
            lineSize="20"
          />
          <div className="w-full flex flex-col lg:flex-row justify-between lg:space-x-2 space-y-4 lg:space-y-0">
            <ServiceListCard
              title={data.cards.card[0].cardHeading}
              list={data.cards.card[0].cardDescriptionList}
            />
            <ServiceListCard
              title="Detailed descriptions of the IT infrastructure and operating procedures:"
              list={[
                "SOPs for ticket resolution, CI/CD flows.",
                "Network maps.",
                "Configuration management database.",
                "Infrastructure improvement plan.",
              ]}
            />
            <ServiceListCard
              title="Self-service training materials for users:"
              list={["Knowledge base articles.", "FAQs.", "User manuals."]}
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row justify-between lg:space-x-2 space-y-4 lg:space-y-0">
            <ServiceListCard
              title="User satisfaction and adoption improvements:"
              list={[
                "UX testing/monitoring.",
                "Surveys and user experience studies with CSAT improvement plans based on their results.",
              ]}
            />
            <ServiceListCard
              title="Regulatory compliance assessments."
              list={[
                "IT environment benchmarking against PCI DSS, HIPAA, etc.",
                "Compliance gap mitigation plans.",
              ]}
            />
            <ServiceListCard
              title="Regular and transparent reporting:"
              list={[
                "Service level reports.",
                "Maintenance reports.",
                "Health check reports.",
                "Security assessment reports.",
                "Incident reports with root cause analysis.",
              ]}
            />
          </div>
        </div>

        <div className="mt-10 space-y-4">
          <Heading
            title="Why Choose M2ITCare"
            titleType={isMatched ? "h4" : "h3"}
            lineSize={20}
          />
          <div className="space-y-3 pl-2">
            {WhyUs.map((item) => {
              return (
                <div className="flex items-center space-x-2">
                  <div className="w-2 h-2 bg-blue-700"></div>
                  <Typography variant="body1">{item}</Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePage2;
