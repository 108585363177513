import { Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { FiArrowDownRight } from "react-icons/fi";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
// import

const OurSolutions = [
  { link: "enterprise-applications", title: "Enterprise Applications" },
  { link: "erp", title: "ERP" },
  { link: "crm", title: "CRM" },
  { link: "hr-software", title: "HR Software" },
  { link: "elearning-software", title: "eLearning Software" },
  { link: "ecommerce", title: "Ecommerce" },
  { link: "mobile-apps", title: "Mobile Apps" },
  { link: "collaboration-solutions", title: "Collaboration Solutions" },
  { link: "fleet-management-software", title: "Fleet Management Software" },
  { link: "data-analytics", title: "Data Analytics" },
  { link: "web-portals", title: "Web Portals" },
  { link: "itsm", title: "ITMS" },
];

const Solutions = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    height: isMatched === true ? 80 : 100,
    width: isMatched === true ? 380 : 280,
    borderRadius: 0,
    color: theme.palette.text.secondary,
    // background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    background: "white",
    boxShadow: "0 2px 5px 2px rgba(212, 212, 212, .5)",
  }));

  return (
    <div id="solutions" className="lg:p-16 p-2 pb-10 bg-[#0B3155] space-y-10">
      <div className="space-y-2  lg:mt-0 mt-8">
        <Typography
          variant="p"
          className="lg:text-4xl text-2xl text-white font-bold"
        >
          Solutions We Deliver
        </Typography>
        <div className="bg-[#EC8A00] h-0.5 lg:w-32 w-24 mb-8"></div>
        <div className="w-full">
          <Typography
            variant="p"
            className="text-lg w-full text-white font-medium"
          >
            We IT-enable all kinds of B2B, B2C interactions and internal
            operations.
          </Typography>
        </div>
      </div>
      <div>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
          {OurSolutions.map((item) => (
            <Grid item xs={isMatched ? 12 : 3}>
              <Item className="">
                <Link to={`/solution/${item.link}`}>
                  <div className="lg:mt-8 mt-4">
                    <Typography
                      variant="p"
                      className="text-lg text-white font-medium text-black"
                    >
                      {item.title}
                    </Typography>
                  </div>
                  <div className="flex self-center w-full justify-end items-end lg:mt-0 -mt-2">
                    <FiArrowDownRight color="blue" size={20} />
                  </div>
                </Link>
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Solutions;
