import { Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import whyus from "../../assests/whyus.jpg";

const WhyUs = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div id="aboutus" className="p-4 py-10 w-full flex lg:flex-row flex-col">
      <div className="lg:w-1/3 w-full ">
        <img src={whyus} />
      </div>
      <div className="lg:w-2/3 w-full lg:p-10 p-4 lg:pt-20 lg:pl-20 space-y-10">
        <div className="space-y-2 flex flex-col items-center">
          <Typography
            variant="p"
            className="lg:text-4xl text-2xl text-black self-center font-bold"
          >
            Why Us?
          </Typography>
          <div className="bg-[#EC8A00] h-0.5 w-32 mb-8"></div>
        </div>
        <Typography
          level="p"
          className="text-lg font-normal text-gray-500 text-center"
        >
          M2ITCare Provides top notch Web and Mobile App Solutions,
          we develop high quality and innovative Web and Mobile Applications
          which can be easily stand out in competitive market. Our Developers
          Work on latest and trending technologies and we always deliver
          requirement specific and user centric applications as per The business
          needs.
        </Typography>
        <div
          className={isMatched ? "flex flex-col space-y-10" : "flex space-x-20"}
        >
          <div className="flex  flex-row space-x-16">
            <div>
              <Typography
                variant="p"
                className="text-[#FF3234] text-5xl font-medium"
              >
                30+
              </Typography>
              <Typography level="p" className="text-gray-500">
                Completed Project
              </Typography>
            </div>
            <div>
              <Typography
                variant="p"
                className="text-[#FF3234] text-5xl font-medium"
              >
                70+
              </Typography>
              <Typography level="p" className="text-gray-500">
                Sites Served
              </Typography>
            </div>
          </div>
          <div className="flex  flex-row space-x-16">
            <div>
              <Typography
                variant="p"
                className="text-[#FF3234] text-5xl font-medium"
              >
                2500+
              </Typography>
              <Typography level="p" className="text-gray-500">
                Hours of Coding
              </Typography>
            </div>
            <div>
              <Typography
                variant="p"
                className="text-[#FF3234] text-5xl font-medium"
              >
                5+
              </Typography>
              <Typography level="p" className="text-gray-500">
                Years of Busines
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
