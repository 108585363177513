import { Divider, Typography } from "@mui/material";
import React from "react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  return (
    <div className="bg-[#0B3155] p-4 pt-8 lg:px-20 lg:space-y-5 space-y-10">
      <div className="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 ">
        <Typography variant="h4" color="white">
          M2-ITCARE
        </Typography>
        <div className="flex space-x-4">
          <div className="text-white">
            <FmdGoodIcon />
          </div>
          <div>
            <Typography variant="body2" color="white">
            Lathgali-08, Birgunj
            </Typography>
            <Typography variant="body2" color="white">
              Parsa, Nepal.
            </Typography>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="text-white">
            <LocalPhoneIcon />
          </div>
          <div>
            <Typography variant="body2" color="white">
              +977-9807207940
            </Typography>
            <Typography variant="body2" color="white">
              +977-9802074046
            </Typography>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <div className="text-white">
            <EmailIcon />
          </div>
          <Typography variant="body2" color="white">
          manish@m2itcare.com
          </Typography>
        </div>
        <div className="flex items-center space-x-3">
          <div className="text-white">
            <a href="https://www.facebook.com/m2itcare">
              <FacebookIcon/>
            </a>
          </div>
          <div className="text-white">
            <a href="https://wa.me/9779807207940">
              <WhatsAppIcon/>
            </a>
          </div>
          <div className="text-white">
            <TwitterIcon />
          </div>
          <div className="text-white">
            <a href="mailto:manish@m2itcare.com">
              <EmailIcon />
            </a>
          </div>
        </div>
      </div>
      <Divider color="white" />
      <div>
        <div className="flex justify-center space-x-8 text-white ">
          <Typography>About Us</Typography>
          <Typography>Become Our Agent</Typography>
          <Typography>Privacy Policy</Typography>
          <Typography>Terms of Use</Typography>
        </div>
      </div>
      <div>
        <div className="flex flex-col lg:flex-row justify-center items-center space-x-4 text-white ">
          <Typography>© 2023 M2 ITCARE PVT. LTD.</Typography>
          <Typography>All rights reserved.</Typography>
        </div>
      </div>
    </div>
  );
};

export default Footer;
