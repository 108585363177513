import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import hacker1 from "../../assests/hacker1.jpg";
import hacker2 from "../../assests/hacker2.jpg";

const ItWarning = () => {
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div className="flex flex-col lg:flex-row w-full lg:p-4 p-2">
      <div className="lg:w-1/2 lg:p-8 p-2 flex flex-col lg:flex-row items-center">
        <div>
          <img
            src={hacker1}
            style={{
              height: isMatched ? "100%": 220,
              width: isMatched ? "100%": 350,
              borderTopLeftRadius: isMatched ? 0: 10,
              borderBottomLeftRadius: isMatched ? 0: 10,
            }}
          />
        </div>
        <div className="p-2 lg:h-96 lg:w-96 h-64 lg:rounded-2xl rounded-b-2xl bg-[#3096F0] flex flex-col items-center justify-center lg:space-y-10">
          <Typography variant="h3" className="text-white">
            43%
          </Typography>
          <div className="flex items-center justify-center">
            <div className="w-12 h-0.5 bg-white"></div>
            <div className="w-3 h-3 rounded-full border-2 border-white bg-[#3096F0]"></div>
          </div>
          <Typography variant="h6" className="text-white text-center">
            of cyber attacks target small business.
          </Typography>
        </div>
      </div>
      <div className="lg:w-1/2 lg:p-8 p-2 flex flex-col lg:flex-row items-center">
        <div className="p-2 lg:h-96 lg:w-96 h-64 lg:rounded-2xl rounded-t-2xl bg-[#3096F0] flex flex-col items-center justify-center lg:space-y-10">
          <Typography variant="h3" className="text-white">
            74%
          </Typography>
          <div className="flex items-center justify-center">
            <div className="w-12 h-0.5 bg-white"></div>
            <div className="w-3 h-3 rounded-full border-2 border-white bg-[#274D90]"></div>
          </div>
          <Typography variant="h6" className="text-white text-center">
            of SMBs lack personnel to effectively mitigate their cyber risks and
            defend against a cyberattack.
          </Typography>
        </div>
        <div>
          <img
            src={hacker2}
            style={{
              height: isMatched ? "100%": 220,
              width: isMatched ? "100%": 350,
              borderTopRightRadius: isMatched ? 0: 10,
              borderBottomRightRadius: isMatched ? 0: 10,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ItWarning;
