import { Typography, useMediaQuery, useTheme, Box } from "@mui/material";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import React, { useState } from "react";
import one from "../../assests/meta.png";
import two from "../../assests/linkedin.png";
import three from "../../assests/google.png";

import { TechnologyIcon } from "../../assests/data";
import TechnologyIconCard from "./TechnologyIconCard";
import TechnologyCard from "./TechnologyCard";

const Technology = () => {
  const icon = TechnologyIcon.web;
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedTechnology, setSelectedTechnology] = useState("Web");

  const Web = () => {
    return (
      <div className="space-y-4">
        <div className="space-y-2">
          <Typography variant="p" className="text-4xl font-bold">
            Web
          </Typography>
          <div className="bg-[#EC8A00] h-0.5 w-20"></div>
        </div>
        <Typography>BACK END</Typography>
        <div className="flex space-x-2 ">
          {icon.backEnd.map((item) => {
            return <TechnologyIconCard IconName={item} />;
          })}
        </div>
        <Typography>FRONT END</Typography>
        <div className="flex space-x-2 ">
          {icon.frontEnd.map((item) => {
            return <TechnologyIconCard IconName={item} />;
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      id="technology"
      className="lg:p-16 p-2 pt-4 bg-[#fff] lg:space-y-10 space-y-4"
    >
      <div className="space-y-2">
        <Typography variant="p" className="lg:text-4xl text-2xl font-bold">
          Technologies and Platforms We Work With
        </Typography>
        <div className="bg-[#EC8A00] h-0.5 lg:w-32 w-16"></div>
      </div>
      <div className="w-full flex ">
        {isMatched ? (
          <div className="w-full">
            {/* <Typography>WEB</Typography> */}
            <CarouselProvider
              naturalSlideHeight={70}
              naturalSlideWidth={50}
              totalSlides={3}
              visibleSlides={1}
            >
              <Slider>
                <Slide index={0}>
                  <Box className="flex flex-col">
                    <div className="bg-[#3f00ec] h-2 w-full"></div>
                    <Box className="p-6 space-y-2">
                      <TechnologyCard
                        title={"Mobile"}
                        iconList={TechnologyIcon["Mobile"]}
                      />
                    </Box>
                  </Box>
                </Slide>
                <Slide index={1}>
                  <Box className="flex flex-col">
                    <div className="bg-[#3f00ec] h-2 w-full"></div>
                    <Box className="p-6 space-y-2">
                    <TechnologyCard
                        title={"Desktop"}
                        iconList={TechnologyIcon["Desktop"]}
                      />
                    </Box>
                  </Box>
                </Slide>
                <Slide index={2}>
                  <Box className="flex flex-col">
                    <div className="bg-[#3f00ec] h-2 w-full"></div>
                    <Box className="p-6 space-y-2">
                    <TechnologyCard
                        title={"Cloud"}
                        iconList={TechnologyIcon["Cloud"]}
                      />
                    </Box>
                  </Box>
                </Slide>
              </Slider>
            </CarouselProvider>
          </div>
        ) : (
          <>
            <div className="w-1/3 border flex flex-row">
              <ul className=" w-full">
                <div
                  className={
                    selectedTechnology === "Web" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Web" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Web")}
                  >
                    Web
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Mobile" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Mobile" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Mobile")}
                  >
                    Mobile
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Desktop" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Desktop" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Desktop")}
                  >
                    Desktop
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Platform" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Platform" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Platform")}
                  >
                    Platform
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Database" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Database" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Database")}
                  >
                    Database
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Bigdata" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Bigdata" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Bigdata")}
                  >
                    Bigdata
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Cloud" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Cloud" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Cloud")}
                  >
                    Cloud
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Artificial Intelligence"
                      ? "flex bg-gray-100"
                      : ""
                  }
                >
                  {selectedTechnology === "Artificial Intelligence" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() =>
                      setSelectedTechnology("Artificial Intelligence")
                    }
                  >
                    Artificial Intelligence
                  </li>
                </div>
                <div
                  className={
                    selectedTechnology === "Machine Learning"
                      ? "flex bg-gray-100"
                      : ""
                  }
                >
                  {selectedTechnology === "Machine Learning" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Machine Learning")}
                  >
                    Machine Learning
                  </li>
                </div>

                <div
                  className={
                    selectedTechnology === "Devops" ? "flex bg-gray-100" : ""
                  }
                >
                  {selectedTechnology === "Devops" ? (
                    <div className="w-1 h-16 bg-blue-700"></div>
                  ) : (
                    <></>
                  )}
                  <li
                    className="text-xl p-4 pl-8 text-gray-500 font-medium cursor-pointer"
                    onClick={() => setSelectedTechnology("Devops")}
                  >
                    Devops
                  </li>
                </div>
              </ul>
            </div>
            <div className="lg:w-2/3 w-full bg-[#FAFAFA] border p-8">
              {selectedTechnology === "Web" ? (
                <Web />
              ) : (
                <TechnologyCard
                  title={selectedTechnology}
                  iconList={TechnologyIcon[selectedTechnology]}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Technology;
